import config from './config.json'

import { get } from 'lodash'

import root from 'utils/windowOrGlobal'

export default {
  ...config,
  WINDOW_HEIGHT: Math.max(
    get(root, 'document.documentElement.clientHeight', 0),
    get(root, 'window.innerHeight', 0)
  ),
  WINDOW_WIDTH: Math.max(
    get(root, 'document.documentElement.clientWidth', 0),
    get(root, 'window.innerWidth', 0)
  ),
  PRIME_PRICE: 3.49,
  PRIME_FIRST_PRICE: 10.49,
  REGULAR_DEALS_PERCENT_FREE: 50,
  BUSINESS_DEALS_PERCENT_FREE: 50,
  REGULAR_DEALS_PERCENT_PRIME: 100,
  BUSINESS_DEALS_PERCENT_PRIME: 50,
  REGULAR_DEALS_PERCENT_PRIME_FC: 100,
  BUSINESS_DEALS_PERCENT_PRIME_FC: 100,
  AIRPORTS_PRIME: 10,
  AIRPORTS_FREE: 3
}
