import React, { useCallback, useEffect, useState } from 'react'
import { parseQueryString } from 'utils/parse-querystring'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { FullPageLoader } from 'components/Loader'
import { checkGoogleOuth } from './_api'
import camelize from 'utils/camelize'
import { handleLogin } from 'stores/auth'
import * as routes from 'config/routes'
import colors from 'styles/colors'

const FullPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`
export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 20px;
  color: ${colors.black};
  width: 60%;
  text-align: center;
`

const AuthorizeLogin = ({ location }) => {
  const [successMsg, setSuccessMsg] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)
  const query = parseQueryString(location.search)

  const checkOauth = useCallback(async () => {
    try {
      if (query && query.error && query.error === 'access_denied') {
        return navigate(routes.HOME)
      }
      if (query && query.login && query.login === 'apple') {
        if (query.error) {
          console.log('Error [AppleOauth]', query.error)
          throw new Error(query.error)
        }
        handleAppleLogin()
        return
      }
      const [error, data] = await checkGoogleOuth(query)
      if (error) {
        console.log('Error [GoogleOauth]', error)
        throw new Error(error)
      }
      const { message, user } = data

      if (!message) {
        throw new Error(
          `Sorry, something went wrong. Please try again.  \n\n ${data}`
        )
      }
      await handleLogin(user, true)
      setSuccessMsg('Logging in...')
      setTimeout(() => {
        navigate(routes.APP)
      }, 1000)
    } catch (err) {
      console.log('Error [Oauth catch]', err)
      let error
      if (typeof err === 'string') {
        error = err
      } else if (err && err.name && err.message) {
        error = `${err.name}: ${err.message}`
      }
      setErrorMsg(error)
      setTimeout(() => {
        navigate(routes.APP)
      }, 2000)
    }
  }, [])

  const handleAppleLogin = async () => {
    const { login, ...rest } = query
    const user = camelize(rest)
    await handleLogin(user, true)
    setSuccessMsg('Logging in...')
    setTimeout(() => {
      navigate(routes.APP)
    }, 1000)
  }

  useEffect(() => {
    checkOauth()
  }, [])

  if (successMsg) {
    return (
      <FullPage>
        <Text>{successMsg}</Text>
      </FullPage>
    )
  }

  if (errorMsg) {
    return (
      <FullPage>
        <Text>{errorMsg}</Text>
      </FullPage>
    )
  }

  return <FullPageLoader />
}

export default AuthorizeLogin
