export default {
  blackRGBA: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
  black: '#222223',
  lightBlack: '#4F4F4F',
  nobel: '#b5b5b5',

  white: '#ffffff',
  whiteRGBA: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
  offwhite: '#f8f8f8',
  ice: '#F7FAFC',
  darkIce: '#E4ECF7',
  silver: '#999',

  lightestBlue: '#BAD9FF',
  lightBlue: '#89C0FF',
  lightBlueI: '#D8E9FF',
  lightBlueII: '#CAE1FF',
  lightBlueIII: '#6F839A',
  darkBlueI: '#35567E',
  darkBlueII: '#2F72C5',
  darkestBlue: '#153C6B',
  blue: '#53a0ff',
  blueRGBA: (opacity = 1) => `rgba(83, 160, 255, ${opacity})`,
  bluesh: '#1C81FF',
  darkBlue: '#2d89f8',
  darkerBlue: '#2f80ed',
  semiBlueX: '#CAE1FF',
  buttonBlue: '#5092E2',
  babyBlue: '#BAD9FF',
  lightestRed: '#FFE9E5',
  red: '#f18472',
  redRGBA: (opacity = 1) => `rgba(241, 132, 114, ${opacity})`,
  darkRed: '#fc6148',
  darkerRed: '#eb5757',
  terraCotta: '#DE7564', // (sorry) name from http://www.color-blindness.com/color-name-hue/
  grenadier: '#C24E3A', // (sorry) name from http://www.color-blindness.com/color-name-hue/

  yellow: '#ffbe5e',
  lightYellow: '#FCEFDC',
  activeYellow: '#FFF5E5',

  darkGreen: '#216E41',
  pigmentGreen: '#00B247',
  lightGreen: '#96D6B1'
}
