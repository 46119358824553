import { navigate } from 'gatsby'

export const parseQueryString = (string = '') => {
  if (!string || typeof string.slice !== 'function') return string

  return string
    .slice(1)
    .split('&')
    .map(queryParam => {
      let kvp = queryParam.split('=')
      return { key: kvp[0], value: kvp[1] }
    })
    .reduce((query, kvp) => {
      query[kvp.key] = decodeURIComponent(kvp.value)
      return query
    }, {})
}

export default parseQueryString

export function getUrlParameter (location, name) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')

  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const results = regex.exec(location.search)

  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

// Turns {token: "123"} into token=123
export function stringifyQuery (query) {
  return Object.keys(query)
    .filter(key => key && query[key])
    .reduce((prev, key) => {
      const param = `${key}=${encodeURIComponent(query[key])}`
      return prev ? `${prev}&${param}` : param
    }, '')
}

export function push ({ pathname, query, options }) {
  const path = `${pathname}${query ? '?' + stringifyQuery(query) : ''}`
  return navigate(path, options)
}
